import { SET_PROFIT_OVERVIEW_V2_STATE } from 'constants/action_types/sales_analytics/profit_overview'

export const initialState = {
  currentTab: 0, // Company View | Multi-Product View
  formattedDates: {},
  selectedProduct: undefined,
  isLoading: true,
  data: undefined,
  sortBy: undefined
}

function profitOverviewV2(state = initialState, action) {
  switch (action.type) {
    case SET_PROFIT_OVERVIEW_V2_STATE:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}

export default profitOverviewV2
