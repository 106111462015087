import { PRODUCT_TABLE_COLUMNS_NAMES } from 'constants/profit_overview'
import { LOCATION_CHANGE } from 'connected-react-router'

import * as types from '../../constants/action_types/sales_analytics/profit_overview'
import * as sharedTypes from '../../constants/action_types/sales_analytics/shared'
import { PPC_SYNC_STATUS } from '../../constants/account'

import {
  getCalendarModalPreferencesFromLocalStorage,
  setCalendarModalPreferencesInLocalStorage
} from '../../helpers/sales_analytics/sales_analytics'
import {
  defaultUnitSoldData,
  defaultProfitInfoData,
  defaultRevenueSummaryData,
  defaultCostData,
  sharedInitialState,
  buildPendingState,
  buildCompletedState,
  buildToggleCalendarState,
  buildSetDateRangeState,
  buildSetComparisonDateRangeState,
  buildDataState,
  buildResetDataState
} from './shared'
import { getPreviousDateRange } from 'helpers/date'

const LOCAL_STORAGE_PROFIT_OVERVIEW_KEY = 'LOCAL_STORAGE_PROFIT_OVERVIEW_KEY'

function saveCalendarData(state) {
  setCalendarModalPreferencesInLocalStorage(
    LOCAL_STORAGE_PROFIT_OVERVIEW_KEY,
    state.dates
  )
}

const initialDates = () => {
  const dates = getCalendarModalPreferencesFromLocalStorage(
    LOCAL_STORAGE_PROFIT_OVERVIEW_KEY,
    sharedInitialState.dates
  )

  // Ensure there are start and end dates, in case of corrupted local storage data
  if (!dates.startDate || !dates.endDate) {
    dates.startDate = sharedInitialState.dates.startDate
    dates.endDate = sharedInitialState.dates.endDate
  }

  if (!dates.comparisonType) {
    dates.comparisonType = 'previous_period'
  }

  // Ensure there is always a comparison start and end date
  if (!dates.comparisonEndDate || !dates.comparisonStartDate) {
    const { previousStartDate, previousEndDate } = getPreviousDateRange(
      dates.startDate,
      dates.endDate
    )
    dates.comparisonStartDate = previousStartDate
    dates.comparisonEndDate = previousEndDate
  }

  return dates
}

export const initialState = {
  ...sharedInitialState,
  dates: initialDates(),
  productFilterOn: false,

  // Product Filter
  selectedProduct: {},
  products: [],
  pagination: {},
  sorting: { column: 'total_sales', direction: 'desc' },
  search: '',
  selectedProductsTableColumns: PRODUCT_TABLE_COLUMNS_NAMES,

  previousUnitsSoldData: {
    ...defaultUnitSoldData
  },

  previousProfitInfoData: {
    ...defaultProfitInfoData
  },

  previousCostSummaryData: {
    ...defaultCostData
  },

  previousCostOverTimeData: [],

  // Summary Over Time
  activeSummaryTab: 'net_profit',

  previousRevenueOverTimeData: [],

  unitsSoldOverTimeData: [],

  previousUnitsSoldOverTimeData: [],

  previousRevenueSummaryData: {
    ...defaultRevenueSummaryData
  },

  previousNetProfitOverTimeData: [],

  // Loading states
  loadProducts: {
    isLoading: false,
    isSearching: false
  },

  previousUnitsSold: {
    isLoading: false
  },

  roi: {
    isLoading: false
  },

  previousRoi: {
    isLoading: false
  },

  profitInfo: {
    isLoading: false
  },

  previousProfitInfo: {
    isLoading: false
  },

  previousNetMargin: {
    isLoading: false
  },

  unitsSoldOverTime: {
    isLoading: false
  },

  chartView: 'daily', // ['daily', 'weekly', 'monthly']

  insights: {
    highlightRow: undefined,
    asin: undefined
  }
}

const closePpcModal = state => {
  return {
    ...state,
    ppc: {
      ...state.ppc,
      modalOpen: null
    }
  }
}

function profitOverviewReducer(state = initialState, action) {
  switch (action.type) {
    case types.PROFIT_OVERVIEW_CHANGE_SEARCH: {
      return {
        ...state,
        loadProducts: {
          ...state.loadProducts,
          searchTerm: action.payload.searchTerm,
          isSearching: !!action.payload.searchTerm
        }
      }
    }
    case sharedTypes.SALES_ANALYTICS_PENDING_STATE: {
      return buildPendingState(state, action)
    }
    case sharedTypes.SALES_ANALYTICS_COMPLETED_STATE: {
      return buildCompletedState(state, action)
    }
    case types.PROFIT_OVERVIEW_TOGGLE_PRODUCT_FILTER: {
      const newFilter = !state.productFilterOn
      const initialProduct = state.products.length > 0 ? state.products[0] : {}

      return {
        ...state,
        productFilterOn: newFilter,
        selectedProduct: state.productFilterOn ? {} : initialProduct
      }
    }
    case types.PROFIT_OVERVIEW_SET_PRODUCT_FILTER: {
      return {
        ...state,
        productFilterOn: action.payload.isProductSearch,
        selectedProduct: {}
      }
    }
    case types.PROFIT_OVERVIEW_LOAD_PRODUCTS: {
      const { products } = action.payload
      let { selectedProduct } = state

      if (!state.loadProducts.isSearching) {
        selectedProduct =
          Array.isArray(products) && products.length > 0
            ? { ...products[0] }
            : {}
      }

      return {
        ...state,
        products,
        selectedProduct
      }
    }

    case types.PRODUCTS_TABLE_LOAD_PAGINATION: {
      return {
        ...state,
        pagination: action.payload
      }
    }

    case types.SET_PRODUCTS_TABLE_PAGE: {
      return {
        ...state,
        pagination: { ...state, current_page: action.payload }
      }
    }

    case types.SET_PRODUCTS_SORT_COLUMN: {
      return {
        ...state,
        sorting: { column: action.column, direction: action.direction }
      }
    }

    case types.SET_PRODUCTS_TABLE_SEARCH: {
      return {
        ...state,
        search: action.payload
      }
    }

    case types.PROFIT_OVERVIEW_SELECT_PRODUCT: {
      return {
        ...state,
        selectedProduct: action.payload
      }
    }
    case types.PROFIT_OVERVIEW_SET_ACTIVE_SUMMARY_TAB: {
      return {
        ...state,
        activeSummaryTab: action.payload
      }
    }
    case types.PROFIT_OVERVIEW_SET_INSIGHTS: {
      const { insights } = state
      const { payload } = action
      return {
        ...state,
        insights: { ...insights, ...payload }
      }
    }
    case sharedTypes.TOGGLE_SALES_ANALYTICS_CALENDAR_MODAL: {
      return buildToggleCalendarState(state)
    }
    case sharedTypes.SET_DATE_RANGE: {
      const newState = buildSetDateRangeState(state, action)
      saveCalendarData(newState)
      return newState
    }
    case sharedTypes.SET_COMPARISON_DATE_RANGE: {
      const newState = buildSetComparisonDateRangeState(state, action)
      saveCalendarData(newState)
      return newState
    }
    // Widget / Graph data
    case sharedTypes.SALES_ANALYTICS_LOAD_DATA: {
      return buildDataState(state, action)
    }
    case sharedTypes.SALES_ANALYTICS_RESET_DATA: {
      return buildResetDataState(state, action, initialState)
    }
    case types.PROFIT_OVERVIEW_UPDATE_CHART_VIEW: {
      return {
        ...state,
        chartView: action.payload
      }
    }
    // PPC
    case types.PROFIT_OVERVIEW_PENDING_PPC_TOTALS:
      return {
        ...state,
        ppc: {
          ...state.ppc,
          isLoadingTotals: true
        }
      }
    case types.PROFIT_OVERVIEW_PENDING_PPC_CHART:
      return {
        ...state,
        ppc: {
          ...state.ppc,
          isLoadingDataOverTime: true
        }
      }
    case types.PROFIT_OVERVIEW_RECEIVE_PPC_TOTALS:
      return {
        ...state,
        ppc: {
          ...state.ppc,
          totals: action.ppcTotals
        }
      }
    case types.PROFIT_OVERVIEW_UPDATE_PPC_CHART:
      return {
        ...state,
        ppc: {
          ...state.ppc,
          dataOverTime: action.ppcOverTime
        }
      }
    case types.PROFIT_OVERVIEW_COMPLETED_PPC_TOTALS:
      return {
        ...state,
        ppc: {
          ...state.ppc,
          isLoadingTotals: false
        }
      }
    case types.PROFIT_OVERVIEW_COMPLETED_PPC_CHART:
      return {
        ...state,
        ppc: {
          ...state.ppc,
          isLoadingDataOverTime: false
        }
      }
    case types.PROFIT_OVERVIEW_SHOW_PPC_MODAL:
      // If ppc sync did not succeed, revert to initial when changing modals
      const newPpcSyncStatus =
        state.ppc.ppcSyncStatus === PPC_SYNC_STATUS.failure
          ? PPC_SYNC_STATUS.initial
          : state.ppc.ppcSyncStatus
      return {
        ...state,
        ppc: {
          ...state.ppc,
          modalOpen: action.payload.modal,
          ppcSyncStatus: newPpcSyncStatus
        }
      }
    case types.PROFIT_OVERVIEW_CLOSE_PPC_MODAL:
      return closePpcModal(state)
    case types.PROFIT_OVERVIEW_SET_PPC_SYNC_STATUS:
      return {
        ...state,
        ppc: {
          ...state.ppc,
          ppcSyncStatus: action.payload
        }
      }
    case types.PROFIT_OVERVIEW_SET_PRODUCT_SELECTED_COLUMNS:
      return {
        ...state,
        selectedProductsTableColumns: action.payload
      }
    case LOCATION_CHANGE:
      const cleanedState = [closePpcModal].reduce(
        (state, func) => func(state),
        state
      )
      return cleanedState
    case types.RESET_PROFIT_OVERVIEW: {
      return {
        ...initialState
      }
    }
    default:
      return state
  }
}

export default profitOverviewReducer
