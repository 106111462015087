import * as sharedTypes from '../../constants/action_types/sales_analytics/shared'
import * as profitAndLossTypes from '../../constants/action_types/sales_analytics/profit_and_loss'

import {
  getCalendarModalPreferencesFromLocalStorage,
  setCalendarModalPreferencesInLocalStorage
} from '../../helpers/sales_analytics/sales_analytics'
import {
  sharedInitialState,
  buildPendingState,
  buildCompletedState,
  buildToggleCalendarState,
  buildSetDateRangeState,
  buildSetComparisonDateRangeState,
  buildDataState,
  buildResetDataState
} from './shared'

export const INITIAL_COMPARISON_STATE = {
  previousCostSummary: { isLoading: false },
  previousNetMargin: { isLoading: false },
  previousRevenueSummary: { isLoading: false }
}

const LOCAL_STORAGE_PROFIT_AND_LOSS_KEY = 'LOCAL_STORAGE_PROFIT_AND_LOSS_KEY'

export const initialState = {
  ...sharedInitialState,
  ...INITIAL_COMPARISON_STATE,
  dates: {
    numberOfPreviousPeriods: null,
    // previous_year or previous_period
    comparisonType: null,
    ...getCalendarModalPreferencesFromLocalStorage(
      LOCAL_STORAGE_PROFIT_AND_LOSS_KEY,
      sharedInitialState.dates
    )
  }
}

function saveCalendarData(state) {
  setCalendarModalPreferencesInLocalStorage(
    LOCAL_STORAGE_PROFIT_AND_LOSS_KEY,
    state.dates
  )
}

export function profitAndLossReducer(state = initialState, action) {
  switch (action.type) {
    // Calendar widget
    case profitAndLossTypes.TOGGLE_PROFIT_AND_LOSS_CALENDAR_MODAL: {
      return buildToggleCalendarState(state)
    }
    case profitAndLossTypes.PROFIT_AND_LOSS_SET_DATE_RANGE: {
      const newState = buildSetDateRangeState(state, action)
      saveCalendarData(newState)
      return newState
    }
    case profitAndLossTypes.PROFIT_AND_LOSS_SET_COMPARISON_DATE_RANGE: {
      const newState = buildSetComparisonDateRangeState(state, action)
      const {
        startDate,
        endDate,
        numberOfPreviousPeriods,
        comparisonType
      } = action.payload
      const newComparisonState =
        startDate && endDate ? {} : INITIAL_COMPARISON_STATE

      const serializableState = {
        ...newState,
        dates: {
          ...newState.dates,
          numberOfPreviousPeriods,
          comparisonType
        },
        ...newComparisonState
      }

      saveCalendarData(serializableState)

      return serializableState
    }
    // General states
    case sharedTypes.SALES_ANALYTICS_PENDING_STATE: {
      return buildPendingState(state, action)
    }
    case sharedTypes.SALES_ANALYTICS_COMPLETED_STATE: {
      return buildCompletedState(state, action)
    }
    // Data
    case sharedTypes.SALES_ANALYTICS_LOAD_DATA: {
      return buildDataState(state, action)
    }
    case sharedTypes.SALES_ANALYTICS_RESET_DATA: {
      return buildResetDataState(state, action, initialState)
    }
    case profitAndLossTypes.RESET_PROFIT_AND_LOSS: {
      return {
        ...initialState
      }
    }
    default: {
      return state
    }
  }
}
