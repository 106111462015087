import { calculatePaginationInfo } from 'helpers/pagination'
import * as ACTIONS from '../../constants/action_types/sales_analytics/expenses'
import { LoadingStates } from '../../constants/api'
import { getEndCount } from '../../helpers/pagination'

export const initialState = {
  data: {
    createSaveState: null,
    loadingState: LoadingStates.initialLoad,
    saveState: LoadingStates.initialLoad,
    recurrencesLoading: {},
    expenses: [],
    recurrences: {},
    pagination: {
      perPage: 20,
      startCount: 0,
      endCount: 0,
      totalCount: 0,
      currentPage: null,
      nextPage: null
    },
    search: '',
    orderDir: 'asc',
    orderBy: 'activeExpense.product_title'
  },
  products: {
    data: [],
    isLoading: false,
    pagination: {
      current_page: null,
      next_page: null,
      page_size: 50,
      previous_page: null,
      total_pages: null,
      total_records: 0
    }
  },
  ui: {
    expenseStateLoading: {
      // id : true
    }
  },
  reshippingsData: {
    loadingState: LoadingStates.initialLoad,
    reshippings: [],
    shipment_items: {},
    pagination: {
      perPage: 20,
      startCount: 0,
      endCount: 0,
      totalCount: 0,
      currentPage: null,
      nextPage: null
    },
    search: '',
    orderDir: 'asc',
    orderBy: 'shipment_id'
  },
  categoriesData: {
    loadingState: LoadingStates.initialLoad,
    categories: []
  },
  expensesImportData: {
    loadingState: LoadingStates.initialLoad,
    success: null
  },
  expensesExportData: {
    loadingState: LoadingStates.initialLoad
  }
}

function getEndState(success) {
  return success ? LoadingStates.loaded : LoadingStates.failed
}

function mapExpenses(expenses, expense, parentId) {
  return expenses.map(e => {
    if (e.id !== expense.id && e.id !== parentId) {
      return e
    }

    // if the parent - just update that
    if (e.id === expense.id) {
      return {
        ...e,
        ...expense
      }
    }

    // otherwise find and update the child
    const updatedExpense = {
      ...e,
      activeExpense: { ...expense }
    }

    let index = updatedExpense.child_expenses.findIndex(
      c => c.id === expense.id
    )

    // if we get a new ID back, push to the end of the list
    if (index < 0) {
      index = updatedExpense.child_expenses.length
    }

    updatedExpense.child_expenses[index] = expense

    return updatedExpense
  })
}

export default function productsReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.SALES_ANALYTICS_EXPENSES_LOAD_PENDING_STATUS:
      return {
        ...state,
        data: {
          ...state.data,
          loadingState: action.payload.initialLoad
            ? LoadingStates.initialLoad
            : LoadingStates.loading
        }
      }
    case ACTIONS.SALES_ANALYTICS_EXPENSES_LOAD_COMPLETE_STATUS:
      return {
        ...state,
        data: {
          ...state.data,
          loadingState: LoadingStates.loaded
        }
      }
    case ACTIONS.SALES_ANALYTICS_EXPENSES_LOAD_COMPLETE:
      const {
        pagination,
        expenses,
        goingForwards,
        resetPagination,
        search,
        orderBy,
        orderDir
      } = action.payload

      const paginationData = calculatePaginationInfo(
        expenses,
        state.data.pagination.nextPage,
        goingForwards,
        state.data.pagination,
        'next_page',
        resetPagination
      )

      return {
        ...state,
        data: {
          ...state.data,
          expenses,
          pagination: {
            ...state.data.pagination,
            ...paginationData,
            currentPage: pagination.current_page,
            endCount: getEndCount(
              pagination.current_page,
              state.data.pagination.perPage,
              expenses.length
            ),
            nextPage: pagination.next_page,
            totalCount: Number(pagination.total_records) || 0 // sometimes we get back "0" or similar
          },
          search,
          orderBy,
          orderDir
        }
      }
    case ACTIONS.SALES_ANALYTICS_EXPENSES_PER_PAGE_CHANGED:
      return {
        ...state,
        data: {
          ...state.data,
          pagination: {
            ...state.data.pagination,
            perPage: action.payload.perPage
          }
        }
      }
    case ACTIONS.SALES_ANALYTICS_EXPENSES_DELETE_EXPENSE:
      return {
        ...state,
        data: {
          ...state.data,
          expenses: [
            ...state.data.expenses.filter(e => e.id !== action.payload.id)
          ]
        }
      }
    case ACTIONS.SALES_ANALYTICS_EXPENSES_TOGGLE_EXPENSE_STATE:
      return {
        ...state,
        data: {
          ...state.data,
          expenses: state.data.expenses.map(e => {
            if (e.id !== action.payload.id) {
              return e
            }

            return {
              ...e,
              ...action.payload.expense
            }
          })
        }
      }
    case ACTIONS.SALES_ANALYTICS_EXPENSES_TOGGLE_EXPENSE_PENDING_STATE:
      return {
        ...state,
        ui: {
          ...state.ui,
          expenseStateLoading: {
            ...state.ui.expenseStateLoading,
            [action.payload.id]: true
          }
        }
      }
    case ACTIONS.SALES_ANALYTICS_EXPENSES_TOGGLE_EXPENSE_COMPLETE_STATE:
      return {
        ...state,
        ui: {
          expenseStateLoading: {
            ...state.ui.expenseStateLoading,
            [action.payload.id]: false
          }
        }
      }
    case ACTIONS.REQUEST_EXPENSE_RECURRENCES:
      return {
        ...state,
        data: {
          ...state.data,
          recurrencesLoading: {
            [action.payload.expenseId]: LoadingStates.loading
          }
        }
      }
    case ACTIONS.RECEIVE_EXPENSE_RECURRENCES:
      return {
        ...state,
        data: {
          ...state.data,
          recurrences: {
            ...state.data.recurrences,
            [action.payload.expenseId]: action.payload.recurrences
          }
        }
      }
    case ACTIONS.REQUEST_EXPENSE_RECURRENCES_COMPLETE:
      return {
        ...state,
        data: {
          ...state.data,
          recurrencesLoading: {
            [action.payload.expenseId]: LoadingStates.loaded
          }
        }
      }
    case ACTIONS.RESHIPPINGS_LOAD_PENDING_STATUS:
      return {
        ...state,
        reshippingsData: {
          ...state.reshippingsData,
          loadingState: LoadingStates.loading
        }
      }
    case ACTIONS.RESHIPPINGS_LOAD_COMPLETE:
      return {
        ...state,
        reshippingsData: {
          ...state.reshippingsData,
          reshippings: action.payload.reshippings,
          pagination: {
            ...state.reshippingsData.pagination,
            ...action.payload.pagination
          }
        }
      }
    case ACTIONS.RESHIPPINGS_COMPLETE_STATUS:
      return {
        ...state,
        reshippingsData: {
          ...state.reshippingsData,
          loadingState: LoadingStates.loaded
        }
      }
    case ACTIONS.SET_RESHIPPINGS_PER_PAGE:
      return {
        ...state,
        reshippingsData: {
          ...state.reshippingsData,
          pagination: {
            ...state.reshippingsData.pagination,
            perPage: action.payload.perPage
          }
        }
      }
    case ACTIONS.SET_RESHIPPINGS_PAGE:
      return {
        ...state,
        reshippingsData: {
          ...state.reshippingsData,
          pagination: {
            ...state.reshippingsData.pagination,
            page: action.payload.page
          }
        }
      }
    case ACTIONS.SET_RESHIPPINGS_SEARCH_TERM:
      return {
        ...state,
        reshippingsData: {
          ...state.reshippingsData,
          search: action.payload.searchTerm
        }
      }
    case ACTIONS.SET_RESHIPPINGS_SORT:
      return {
        ...state,
        reshippingsData: {
          ...state.reshippingsData,
          orderBy: action.payload.orderBy,
          orderDir: action.payload.orderDir
        }
      }
    case ACTIONS.SALES_ANALYTICS_EXPENSES_DELETE_RECURRENCE:
      return {
        ...state,
        data: {
          ...state.data,
          recurrences: {
            ...state.data.recurrences,
            [action.payload.expenseId]: state.data.recurrences[
              action.payload.expenseId
            ].filter(r => r.date !== action.payload.date)
          }
        }
      }
    case ACTIONS.SALES_ANALYTICS_EXPENSES_SAVE_EXPENSE_PENDING_STATE:
      return {
        ...state,
        data: {
          ...state.data,
          saveState: LoadingStates.loading,
          expenses: mapExpenses(
            state.data.expenses,
            action.payload.expense,
            action.payload.parentId
          )
        }
      }
    case ACTIONS.SALES_ANALYTICS_EXPENSES_SAVE_EXPENSE_COMPLETE_STATE:
      const { originalExpense } = action.payload
      return {
        ...state,
        data: {
          ...state.data,
          saveState: action.payload.success
            ? LoadingStates.loaded
            : LoadingStates.failed,
          // reset the expense if we failed to update it
          expenses: action.payload.success
            ? state.data.expenses
            : state.data.expenses.map(e => {
                if (e.id !== originalExpense.id) {
                  return e
                }
                return originalExpense
              })
        }
      }
    case ACTIONS.SALES_ANALYTICS_EXPENSES_SAVE_EXPENSE_COMPLETE:
      return {
        ...state,
        data: {
          ...state.data,
          expenses: mapExpenses(
            state.data.expenses,
            action.payload.expense,
            action.payload.parentId
          )
        }
      }
    case ACTIONS.SALES_ANALYTICS_EXPENSES_SAVE_RECURRENCE_PENDING:
      return {
        ...state,
        data: {
          ...state.data,
          saveState: LoadingStates.loading
        }
      }
    case ACTIONS.SALES_ANALYTICS_EXPENSES_SAVE_RECURRENCE_COMPLETE:
      return {
        ...state,
        data: {
          ...state.data,
          saveState: action.payload.saveState
        }
      }
    case ACTIONS.SALES_ANALYTICS_EXPENSES_CREATE_EXPENSE_PENDING_STATE:
      return {
        ...state,
        data: {
          ...state.data,
          createSaveState: LoadingStates.loading
        }
      }
    case ACTIONS.SALES_ANALYTICS_EXPENSES_CREATE_EXPENSE_COMPLETE_STATE:
      return {
        ...state,
        data: {
          ...state.data,
          createSaveState: action.payload.success
            ? LoadingStates.loaded
            : LoadingStates.failed
        }
      }
    case ACTIONS.SALES_ANALYTICS_EXPENSES_CREATE_EXPENSE_COMPLETE:
      // push the expense to the front of the list so it's visible
      return {
        ...state,
        data: {
          ...state.data,
          expenses: [action.payload.expense, ...state.data.expenses]
        }
      }
    case ACTIONS.SALES_ANALYTICS_EXPENSES_PRODUCTS_PENDING_STATE:
      return {
        ...state,
        products: {
          ...state.products,
          isLoading: true
        }
      }
    case ACTIONS.SALES_ANALYTICS_EXPENSES_PRODUCTS_COMPLETE:
      return {
        ...state,
        products: {
          ...state.products,
          data: action.payload.preserveList
            ? [...state.products.data, ...action.payload.products]
            : action.payload.products,
          pagination: action.payload.pagination
        }
      }
    case ACTIONS.SALES_ANALYTICS_EXPENSES_PRODUCTS_COMPLETE_STATE:
      return {
        ...state,
        products: {
          ...state.products,
          isLoading: false
        }
      }
    case ACTIONS.ADD_EXPENSE_CATEGORY_PENDING_STATE:
      return {
        ...state,
        categoriesData: {
          ...state.categoriesData,
          loadingState: LoadingStates.loading
        }
      }
    case ACTIONS.ADD_EXPENSE_CATEGORY_COMPLETE:
      return {
        ...state,
        categoriesData: {
          ...state.categoriesData,
          categories: action.payload.categories
        }
      }
    case ACTIONS.ADD_EXPENSE_CATEGORY_COMPLETE_STATE:
      return {
        ...state,
        categoriesData: {
          ...state.categoriesData,
          loadingState: LoadingStates.loaded
        }
      }
    case ACTIONS.UPLOAD_EXPENSES_CSV_FILE_PENDING_STATE:
      return {
        ...state,
        expensesImportData: {
          ...state.expensesImportData,
          loadingState: LoadingStates.loading
        }
      }
    case ACTIONS.UPLOAD_EXPENSES_CSV_FILE_COMPLETE:
      return {
        ...state,
        expensesImportData: {
          ...state.expensesImportData,
          success: action.payload.success,
          loadingState: getEndState(action.payload.success)
        }
      }

    case ACTIONS.UPLOAD_EXPENSES_CSV_FILE_RESET:
      return {
        ...state,
        expensesImportData: {
          ...state.expensesImportData,
          success: null
        }
      }
    case ACTIONS.EXPORT_EXPENSES_CSV_FILE_PENDING_STATE: {
      return {
        ...state,
        expensesExportData: {
          ...state.expensesExportData,
          loadingState: LoadingStates.loading
        }
      }
    }
    case ACTIONS.EXPORT_EXPENSES_CSV_FILE_COMPLETE_STATE: {
      return {
        ...state,
        expensesExportData: {
          ...state.expensesExportData,
          loadingState: getEndState(action.payload.success)
        }
      }
    }
    case ACTIONS.RESET_EXPENSES: {
      return {
        ...initialState
      }
    }
    default:
      return state
  }
}
